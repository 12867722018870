<template>
    <div>
        <b-row align-v="center">
            <b-col sm="8">
                Name
                <b-input v-model="schedule.name"></b-input>
            </b-col>
            <b-col sm="4">
                <b-form-checkbox v-model="schedule.enabled" class="mt-4">
                    <b>Schema enabled</b>
                </b-form-checkbox>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col class="d-flex align-items-center">
                <b-form-checkbox v-model="schedule.monday" class="mt-4">
                    <b>Monday</b>
                </b-form-checkbox>
            </b-col>
            <b-col>
                <small>Start</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.mondayTimeStart"></b-form-timepicker>
            </b-col>
            <b-col>
                <small>End</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.mondayTimeEnd"></b-form-timepicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex align-items-center">
                <b-form-checkbox v-model="schedule.tuesday" class="mt-4">
                    <b>Tuesday</b>
                </b-form-checkbox>
            </b-col>
            <b-col>
                <small>Start</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.tuesdayTimeStart"></b-form-timepicker>
            </b-col>
            <b-col>
                <small>End</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.tuesdayTimeEnd"></b-form-timepicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex align-items-center">
                <b-form-checkbox v-model="schedule.wednesday" class="mt-4">
                    <b>Wednesday</b>
                </b-form-checkbox>
            </b-col>
            <b-col>
                <small>Start</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.wednesdayTimeStart"></b-form-timepicker>
            </b-col>
            <b-col>
                <small>End</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.wednesdayTimeEnd"></b-form-timepicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex align-items-center">
                <b-form-checkbox v-model="schedule.thursday" class="mt-4">
                    <b>Thursday</b>
                </b-form-checkbox>
            </b-col>
            <b-col>
                <small>Start</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.thursdayTimeStart"></b-form-timepicker>
            </b-col>
            <b-col>
                <small>End</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.thursdayTimeEnd"></b-form-timepicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex align-items-center">
                <b-form-checkbox v-model="schedule.friday" class="mt-4">
                    <b>Friday</b>
                </b-form-checkbox>
            </b-col>
            <b-col>
                <small>Start</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.fridayTimeStart"></b-form-timepicker>
            </b-col>
            <b-col>
                <small>End</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.fridayTimeEnd"></b-form-timepicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex align-items-center">
                <b-form-checkbox v-model="schedule.saturday" class="mt-4">
                    <b>Saturday</b>
                </b-form-checkbox>
            </b-col>
            <b-col>
                <small>Start</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.saturdayTimeStart"></b-form-timepicker>
            </b-col>
            <b-col>
                <small>End</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.saturdayTimeEnd"></b-form-timepicker>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="d-flex align-items-center">
                <b-form-checkbox v-model="schedule.sunday" class="mt-4">
                    <b>Sunday</b>
                </b-form-checkbox>
            </b-col>
            <b-col>
                <small>Start</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.sundayTimeStart"></b-form-timepicker>
            </b-col>
            <b-col>
                <small>End</small>
                <b-form-timepicker hide-header size="sm" v-model="schedule.sundayTimeEnd"></b-form-timepicker>
            </b-col>
        </b-row>
        <hr />
        Schedule exclusions
        <b-row align-v="center">
            <b-col>
                <b-form-checkbox v-model="schedule.closedOnHolidays">
                    <b>Closed on national holidays</b>
                </b-form-checkbox>
            </b-col>
            <b-col align="right">
                <b-button size="sm" variant="primary"><b-icon icon="calendar-week" class="mr-3"></b-icon>Excluded dates</b-button>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col class="d-flex justify-content-end">
                <b-button class="mr-2" @click="cancel()">Cancel</b-button>
                <b-button variant="success" @click="saveSchedule()">{{ (schedule.id) ? 'Save' : 'Create' }}</b-button>
            </b-col>
        </b-row>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import gateScheduleService from '@/services/gateScheduleService';
    import moment from 'moment';
    import i18n from '@/i18n';

    export default {
        props: {
            schedule: {},
        },
        data() {
            return {
                loading: true,
            }
        },
        methods: {
            cancel() {
                this.$root.$emit('bv::hide::modal', 'schedule-modal');
            },
            saveSchedule() {
                if (this.schedule.id > 0) { // Edit
                    gateScheduleService.updateGateSchedule(this.schedule)
                        .then(res => {
                            this.$root.$emit('dmx::saved::gateSchedule');
                        });
                } else { // Create
                    gateScheduleService.createGateSchedule(this.schedule)
                        .then(res => {
                            if (res.data.success) {
                                this.$root.$emit('dmx::created::gateSchedule');
                            }
                        });
                }
            },
        },
    }
</script>