<template>
    <div>
        <b-row>
            <b-col lg="6" sm="12">
                <div class="dmx-gate-card">
                    <b-row no-gutters>
                        <b-col sm="3" align="center">
                            Gate images<br />
                            <b-button v-if="hasGatePermission(permissions.ManageInfo)" size="sm" v-b-modal.modal-1 variant="primary" class="mt-3 mb-3">Upload</b-button>
                            <b-modal v-if="hasGatePermission(permissions.ManageInfo)" id="modal-1" title="Gate image upload">
                                <vue-dropzone ref="myVueDropzone"
                                              id="dropzone"
                                              :options="dropzoneOptions"
                                              v-on:vdropzone-success="postGatePictureUpload" />

                            </b-modal>
                        </b-col>
                        <b-col sm="9">
                            <b-row no-gutters align-v="start">
                                <b-col sm="2" class="pr-sm-1">
                                    <div v-for="(picture, i) in gatePictures">
                                        <div v-if="picture !== gatePictures[0]"
                                             class="dmx-gate-image small mb-1 "
                                             @click="showPicture(picture)"
                                             :style="'background: url(data:image/jpeg;base64,' + picture.base64Image + ') center center no-repeat; background-size: cover;'">
                                        </div>
                                    </div>
                                </b-col>
                                <b-col sm="10">
                                    <div v-if="gatePictures.length" 
                                         @click="showPicture(gatePictures[0])" 
                                         class="dmx-gate-image large"
                                         :style="'background: url(data:image/jpeg;base64,' + gatePictures[0].base64Image + ') center center no-repeat; background-size: cover;'">
                                    </div>
                                    <b-modal id="pictureDialog" title="Gate image edit" size="xl">
                                        <div v-if="selectedImage" 
                                             class="dmx-gate-image mega" 
                                             :style="'background: url(data:image/jpeg;base64,' + selectedImage.base64Image + ') center center no-repeat; background-size: cover;'">
                                        </div>
                                        <hr />
                                        <b-button v-if="hasGatePermission(permissions.ManageInfo)" class="float-right" variant="danger" @click="deleteSelectedImage">Ta bort</b-button>
                                    </b-modal>
                                    <!--<div class="dmx-gate-image large"></div>-->
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <b-row v-if="stateUser.userRole >= userRoles.DemexAdmin" no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            System Gatename:
                        </b-col>
                        <b-col sm="9">
                            <b-form-input v-if="hasGatePermission(permissions.ManageInfo)" v-model="gate.systemName"></b-form-input>
                            <b-form-input v-else v-model="gate.systemName" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row v-if="stateUser.userRole >= userRoles.RegularUser" no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Display Gatename:
                        </b-col>
                        <b-col sm="9">
                            <b-form-input v-if="hasGatePermission(permissions.ManageInfo)" v-model="gate.name"></b-form-input>
                            <b-form-input v-else v-model="gate.name" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row v-if="stateUser.userRole >= userRoles.DemexAdmin" no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Ordernumber:
                        </b-col>
                        <b-col sm="9">
                            <b-form-input v-if="hasGatePermission(permissions.ManageInfo)" v-model="gate.orderNumber"></b-form-input>
                            <b-form-input v-else v-model="gate.orderNumber" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Description:
                        </b-col>
                        <b-col sm="9">
                            <b-form-textarea v-model="gate.gateDescription" spellcheck="false"
                                    autocomplete="off"></b-form-textarea>
                        </b-col>
                    </b-row>
                    <b-row v-if="stateUser.userRole >= userRoles.DemexAdmin" no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Device:
                        </b-col>
                        <b-col sm="9">
                            <b-select v-model="gate.deviceId" :options="deviceListFormatted"></b-select>
                        </b-col>
                    </b-row>

                    <b-row no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            GPS position:
                        </b-col>
                        <b-col sm="9">
                            <b-input-group>
                                <b-form-input v-model="gatePos" placeholder="Not set" disabled></b-form-input>
                                <b-input-group-append>
                                    <b-button v-if="gatePos != ''" variant="primary" v-b-modal.gateMap><b-icon icon="geo-alt"></b-icon></b-button>
                                    <b-button v-else variant="primary" disabled><b-icon icon="geo-alt"></b-icon></b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                    </b-row>

                    <b-row v-if="stateUser.userRole >= userRoles.DemexAdmin" no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Timezone:
                        </b-col>
                        <b-col sm="9">
                            <b-select v-model="gate.timeZoneId" :options="timeZoneListFormatted"></b-select>
                        </b-col>
                    </b-row>

                    <b-row v-if="stateUser.userRole >= userRoles.DemexAdmin" no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Country:
                        </b-col>
                        <b-col sm="9">
                            <b-select v-model="gate.countryId" :options="countryListFormatted"></b-select>
                        </b-col>
                    </b-row>

                    <hr v-if="stateUser.userRole >= userRoles.DemexAdmin" />
                    <b-row v-if="stateUser.userRole >= userRoles.DemexAdmin" no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Owner comp.:
                        </b-col>
                        <b-col sm="9">
                            <b-select v-model="gate.ownerCustomerId" :options="customerOwnerListFormatted"></b-select>
                        </b-col>
                    </b-row>

                    <b-row v-if="stateUser.userRole >= userRoles.DemexAdmin" no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            {{$t('common_gate_installer')}} 
                        </b-col>
                        <b-col sm="9">
                            <b-select v-model="gate.gateInstallerCustomerId" :options="gateInstallerListFormatted"></b-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6" sm="12">
                <div class="dmx-gate-card" v-if="gate.gateStatus">
                    <b-row no-gutters>
                        <b-col align="center" align-v="center" class="status-symbols">
                            <div class="d-flex align-items-center justify-content-center" style="height: 24px;">
                                <span class="dmx-label-small" v-if="gate.gateSchedule && gate.gateSchedule.enabled">
                                    <b-icon variant="primary" icon="exclamation-circle-fill" class="mr-1"></b-icon> This gate has an active schedule, {{ gate.gateSchedule.name }}
                                </span>
                                <span class="dmx-label-small" v-else>
                                    No active schedule 
                                </span>
                            </div>
                            <div class="d-flex align-items-center justify-content-center" style="height: 80px;">
                                <img v-if="gate.gateStatus.state == 10" class="mt-2" :src="'/demexIcons/grind-closed.png'" style="height: 90%;">
                                <img v-if="gate.gateStatus.state == 20" class="mt-2" :src="'/demexIcons/grind-open.png'" style="height: 90%;">
                            </div>
                            <span class="mt-2"><b>{{ stateFormatted }}</b></span>
                        </b-col>
                        <b-col v-if="hasGatePermission(permissions.ViewCycles)" align="center">
                            <div class="d-flex align-items-center justify-content-center" style="height: 24px;">
                                <span class="dmx-label-small" v-if="gate.gateSchedule">
                                    <!-- Commented out line below since I do not know what it has to do with schedule --Martin -->
                                    <!--<b-icon variant="danger" icon="exclamation-circle-fill" class="mr-1"></b-icon> Gate has reached its service interval-->
                                </span>
                                <span class="dmx-label-small" v-else>
                                    --
                                </span>
                            </div>
                            <div class="d-flex align-items-center justify-content-center" style="height: 80px;">
                                <span class="cycle-count"><b>{{ gate.gateStatus.cycleCount }}</b></span>
                            </div>
                            <span class="mt-2"><b>Cycles done</b></span>
                        </b-col>
                    </b-row>
                    <hr class="primary" />
                    <b-row no-gutters>
                        <b-col sm="3" align="center" class="status-symbols">
                            <img src="/demexIcons/battery-full.png" class="status-symbol"><br />
                            <span class="status-status">Good</span><br />
                            <div class="status-label">Battery level</div>
                        </b-col>
                        <b-col sm="3" align="center" class="status-symbols">
                            <img src="/demexIcons/korslinga-ok.png" class="status-symbol"><br />
                            <span class="status-status">Running</span><br />
                            <div class="status-label">Loop</div>
                        </b-col>
                        <b-col sm="3" align="center" class="status-symbols">
                            <img src="/demexIcons/klamskydd-ok.png" class="status-symbol"><br />
                            <span class="status-status">idle</span><br />
                            <div class="status-label">clamp protection</div>
                        </b-col>
                        <b-col sm="3" align="center" class="status-symbols">
                            <img src="/demexIcons/fotocell-ok.png" class="status-symbol"><br />
                            <span class="status-status">idle</span><br />
                            <div class="status-label">Photo cell</div>
                        </b-col>
                    </b-row>
                    <hr class="primary" />
                    <b-row no-gutters>
                        <b-col sm="3" align="center" class="status-symbols">
                            <img src="/demexIcons/styrskap-ok.png" class="status-symbol"><br />
                            <span class="status-status">Closed</span><br />
                            <div class="status-label">Cabinet Door</div>
                        </b-col>
                        <b-col sm="3" align="center" class="status-symbols">
                            <img src="/demexIcons/strom-ok.png" class="status-symbol"><br />
                            <span class="status-status">Connected</span><br />
                            <div class="status-label">Electricity</div>
                        </b-col>
                        <b-col sm="3" align="center" class="status-symbols">
                            <img src="/demexIcons/motorskydd-ok.png" class="status-symbol"><br />
                            <span class="status-status">idle</span><br />
                            <div class="status-label">Engine protection</div>
                        </b-col>
                        <b-col sm="3" align="center" class="status-symbols">

                        </b-col>
                    </b-row>
                    <br />
                    <div v-if="gate.demoMode" class="d-flex align-items-center justify-content-center p-1 mb-2">
                        <b-badge variant="warning"><i>Gate is in demo mode</i></b-badge>
                    </div>
                    <b-row no-gutters class="status-buttons">
                        <b-col v-if="hasGatePermission(permissions.Operate)" sm="6" class="p-1">
                            <b-button size="sm" block align="center" variant="primary"
                                      @click="openGate()">Open gate</b-button>
                        </b-col>
                        <b-col v-if="hasGatePermission(permissions.Operate)" sm="6" class="p-1">
                            <b-button size="sm" block align="center" variant="primary"
                                      @click="closeGate()">Close gate</b-button>
                        </b-col>
                        <b-col v-if="hasGatePermission(permissions.Operate)" sm="6" class="p-1">
                            <b-button size="sm" block align="center" variant="primary"
                                      @click="activateDetector()">Activate detector</b-button>
                        </b-col>
                        <b-col v-if="hasGatePermission(permissions.Operate)" sm="6" class="p-1">
                            <b-button size="sm" block align="center" variant="primary"
                                      @click="deactivateDetector()">De-activate detector</b-button>
                        </b-col>
                        <b-col v-if="hasGatePermission(permissions.Override)" sm="6" class="p-1">
                            <b-button size="sm" block align="center" variant="primary"
                                      @click="activateConstantContactControl()">Activate holding device</b-button>
                        </b-col>
                        <b-col v-if="hasGatePermission(permissions.Override)" sm="6" class="p-1">
                            <b-button size="sm" block align="center" variant="primary"
                                      @click="deactivateConstantContactControl()">De-activate holding device</b-button>
                        </b-col>
                        <b-col v-if="hasGatePermission(permissions.ViewLog)" sm="6" class="p-1">
                            <b-button size="sm" block align="center" variant="primary"
                                      @click="$emit('goto-tab-index', 3)">See gate log</b-button>
                        </b-col>
                        <b-col sm="6" class="p-1">
                            <b-button size="sm" block align="center" variant="warning">Report malfunction</b-button>
                        </b-col>
                    </b-row>
                </div>

            </b-col>
        </b-row>
        <b-modal size="lg" id="gateMap" :title="gate.name">
            <div style="height: 400px;">
                <gate-map :gates="gateArr"></gate-map>
            </div>
        </b-modal>
    </div>
</template>

<style scoped>

    .dmx-gate-image {
        border: 1px solid #ccc;
        background: url('../../assets/demex-img-placeholder.png') center center no-repeat;
        border-radius: 3px;
        width: 100%;
        cursor: pointer;
    }

        .dmx-gate-image.mega {
            height: 800px;
        }        
        
        .dmx-gate-image.large {
            height: 300px;
        }

        .dmx-gate-image.small {
            height: 50px;
        }

    .dmx-label-small {
        font-size: .8em;
    }

    .cycle-count {
        font-size: 3em;
        font-weight: bold;
    }

    .status-symbols {
        border-right: 1px solid #003440;
        line-height: normal;
    }

        .status-symbols:last-child {
            border-right: none;
        }

    .status-symbol {
        max-height: 18px;
    }

    .status-label {
        padding: 0 8px;
        min-height: 40px;
        font-weight: bold;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .status-status, .status-label {
        font-size: .8em;
        text-transform: uppercase;
    }

    hr.primary {
        border-top: 1px solid #003440;
    }
</style>

<script>
    import { mapState, mapActions } from 'vuex';
    import connectService from '@/services/connectService';
    import demoService from '@/services/demoService';
    import gateService from '@/services/gateService';

    import gateMapComponent from './gate-map.vue';
    import vue2Dropzone from 'vue2-dropzone';
    import 'vue2-dropzone/dist/vue2Dropzone.min.css';
    import { UserRoles } from '@/variables/variables';
    import { Permissions } from '@/variables/permissionVariables';

    export default {
        components: {
            'gate-map': gateMapComponent,
            vueDropzone: vue2Dropzone,
        },
        props: {
            gate: {},
            deviceList: [],
            timeZoneList: [],
            countryList: [],
            customerOwnerList: [],
            gateInstallerList: [],
        },
        data() {
            return {
                selectedImage: null,
                gatePictures: [],
                deviceListFormatted: [],
                timeZoneListFormatted: [],
                countryListFormatted: [],
                customerOwnerListFormatted: [],
                gateInstallerListFormatted: [],
                permissions: Permissions,
                userRoles: UserRoles,
            }
        },
        computed: {
            ...mapState('authentication', {
                stateUser: 'user',
            }),
            stateFormatted() {
                let label = '';
                if (this.gate.gateStatus && this.gate.gateStatus.state) {
                    switch (this.gate.gateStatus.state) {
                        case 10:
                            label = 'Closed';
                            break;
                        case 20:
                            label = 'Open';
                            break;
                        case 30:
                            label = 'Opening';
                            break;
                        case 40:
                            label = 'Closing';
                            break;
                        default:
                            label = 'Unknown';
                            break;

                    }
                }
                return label;
            },
            gatePos() {
                let ret = '';
                if (this.gate.deviceLat && this.gate.deviceLong) {
                    ret = `Lat. ${this.gate.deviceLat}, Long. ${this.gate.deviceLong}`;
                }
                return ret;
            },
            gateArr() {
                return [this.gate];
            },
            dropzoneOptions() {
                return {
                    url: '/api/gate/uploadGatePictures',
                    thumbnailWidth: 100,
                    maxFilesize: 0.5,
                    headers: {
                        GateId: this.gate.id,
                    },
                }
            },
        },
        methods: {
            
            deleteSelectedImage() {
                gateService
                    .deleteGatePicture(this.selectedImage.id)
                    .then(() => {
                        this.selectedImage = null;
                        this.getGatePictures();
                        this.$bvModal.hide('pictureDialog');
                    });
            },
            showPicture(pic) {
                this.selectedImage = pic;
                this.$bvModal.show('pictureDialog');
            },
            postGatePictureUpload(file, response) {
                this.getGatePictures();
            },
            getGatePictures() {
                gateService.getGatePictures(this.gate.id)
                    .then(data => {
                        this.gatePictures = data.pictures;
                    });
            },
            openGate() {
                if (this.gate.demoMode == true) {
                    demoService.openGate(this.gate.id).then(data => {
                        //console.log('--------> DEMO openGate');
                    });
                } else {
                    connectService.openGate(this.gate.id).then(data => {
                        //console.log('--------> openGate');
                        //console.log('openGate() data', data);
                    });
                }
            },
            closeGate() {
                if (this.gate.demoMode == true) {
                    demoService.closeGate(this.gate.id).then(data => {
                        //console.log('--------> DEMO closeGate');
                    });
                } else {
                    connectService.closeGate(this.gate.id).then(data => {
                        //console.log('--------> closeGate');
                        //console.log('closeGate() data', data);
                    });
                }
            },
            activateDetector() {
                connectService.activateDetector(this.gate.id).then(data => {
                    //console.log('---> Activate detector');
                    //console.log('activateDetector() data', data);
                });
            },
            deactivateDetector() {
                connectService.deactivateDetector(this.gate.id).then(data => {
                    //console.log('---> Deactivate detector');
                    //console.log('deactivateDetector() data', data);
                });
            },
            activateConstantContactControl() {
                connectService.activateConstantContactControl(this.gate.id).then(data => {
                    //console.log('---> Activate detector');
                    //console.log('activateConstantContactControl() data', data);
                });
            },
            deactivateConstantContactControl() {
                connectService.deactivateConstantContactControl(this.gate.id).then(data => {
                    //console.log('---> Deactivate detector');
                    //console.log('deactivateConstantContactControl() data', data);
                });
            },
        },
        watch: {
            deviceList: {
                handler() {
                    this.deviceListFormatted = [{ value: 0, text: 'Choose device' }];
                    for (let i in this.deviceList) {
                        this.deviceListFormatted.push({ value: this.deviceList[i].id, text: this.deviceList[i].name });
                    }
                },
                immediate: true,
            },
            customerOwnerList: {
                handler() {
                    this.customerOwnerListFormatted = [{ value: 0, text: 'Choose company' }];
                    for (let i in this.customerOwnerList) {
                        this.customerOwnerListFormatted.push({ value: this.customerOwnerList[i].id, text: this.customerOwnerList[i].name });
                    }
                },
                immediate: true,
            },
            gateInstallerList: {
                handler() {
                    this.gateInstallerListFormatted = [{ value: 0, text: 'Choose company' }];
                    for (let i in this.gateInstallerList) {
                        this.gateInstallerListFormatted.push({ value: this.gateInstallerList[i].id, text: this.gateInstallerList[i].name });
                    }
                },
                immediate: true,
            },
            timeZoneList: {
                handler() {
                    this.timeZoneListFormatted = [{ value: 0, text: 'Choose timezone' }];
                    for (let i in this.timeZoneList) {
                        this.timeZoneListFormatted.push({ value: this.timeZoneList[i].id, text: this.timeZoneList[i].name });
                    }
                },
                immediate: true,
            },
            countryList: {
                handler() {
                    this.countryListFormatted = [{ value: 0, text: 'Choose country' }];
                    for (let i in this.countryList) {
                        this.countryListFormatted.push({ value: this.countryList[i].id, text: this.countryList[i].name });
                    }
                },
                immediate: true,
            },
            gate: {
                handler() {
                    this.getGatePictures();
                },
                immediate: true,
            },
        },
    }
</script>