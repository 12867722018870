<template>
    <div>
        <b-row class="mb-2" align-v="center">
            <b-col>
                {{ pagination.totalListCount }} schedules found
            </b-col>
            <b-col>
                <b-input v-model="searchField" placeholder="Search schedules"></b-input>
            </b-col>
            <b-col class="d-flex align-items-end justify-content-end">
                <!--<b-button variant="success" @click="createSchedule">
                    <b-icon icon="plus" class="mr-2"></b-icon> Create schedule
                </b-button>-->
            </b-col>
        </b-row>
        <div class="d-flex align-items-center mb-1">
            <b-link v-if="gateSchedulesSelected.length" @click="deleteSelectedSchedules()" class="mr-3" style="font-size: .8em;">
                <span style="color: #c00 !important;"><b-icon icon="x"></b-icon>Delete selected schedules</span>
            </b-link>
        </div>
        <div v-if="gateScheduleList.length">
            <b-table id="gate-schedules-table"
                     ref="schedulesTable"
                     thead-class="dmx-thead-block"
                     outlined
                     striped
                     bordered
                     :busy="loading"
                     :items="gateScheduleList"
                     :fields="headers"
                     @row-clicked="editSchedule">
                <template #cell(name)="{ item }">
                    <div class="d-flex justify-content-start align-items-center">
                        <b-form-checkbox v-model="gateSchedulesSelected" :value="item.id"></b-form-checkbox>
                        {{ item.name }}
                    </div>
                </template>
                <template #cell(enabled)="{ item }">
                    <b-form-checkbox v-model="item.enabled"></b-form-checkbox>      
                </template>
                <template #cell(modifiedOnUtc)="{ value }">
                    {{ utcFormatted(value) }}
                </template>
            </b-table>
            <b-pagination align="center" v-model="options.page"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.itemsPerPage"
                          aria-controls="gates-log-table">
            </b-pagination>
        </div>
        <div v-else align="center">
            <i>No Schedules found for this gate. <!--<b-link @click="createSchedule">Create one?</b-link>--></i>
        </div>
        <b-modal center size="lg" id="schedule-modal" ref="schedule-modal" hide-footer>
            <template v-if="selectedSchedule" #modal-title>
                Editing {{ selectedSchedule.name || ''}}
            </template>
            <gate-schedule :schedule="selectedSchedule"></gate-schedule>
        </b-modal>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import gateScheduleService from '@/services/gateScheduleService';
    import moment from 'moment';
    import i18n from '@/i18n';

    import gateScheduleComponent from './gate-schedule.vue';

    export default {
        components: {
            'gate-schedule': gateScheduleComponent,
        },
        props: {
            gate: {},
        },
        data() {
            return {
                loading: true,
                searchField: '',
                searchFieldIsLoading: false,
                searchDebouncer: 0,
                gateScheduleList: [],
                gateSchedulesSelected: [],
                options: {
                    page: 1,
                    itemsPerPage: 10,
                },
                pagination: {
                    totalListCount: 0
                },
                selectedSchedule: {
                    id: 0,
                    name: 'New schedule',
                    gateId: this.gate.id,
                },
            }
        },
        methods: {
            deleteSelectedSchedules() {
                this.$bvModal.msgBoxConfirm('Please confirm to delete selected schedules.', {
                    title: 'Deletion',
                    okVariant: 'danger',
                    okTitle: 'DELETE',
                    cancelTitle: 'CANCEL',
                    centered: true
                }).then(goahead => {
                    if (goahead) {
                        recDelete(this.gateSchedulesSelected, 0, (err) => {
                            if (err) {
                                this.makeToast('Schedule deletion', 'Found errors deleting selected schedules.', 'danger');
                            } else {
                                this.makeToast('Schedule deletion', 'Successfully deleted selected schedules.');
                                this.gateSchedulesSelected = [];
                            }
                            this.getGateSchedules();
                        });

                        function recDelete(ob, i, fn, err = false) {
                            if (i < ob.length) {
                                gateScheduleService.deleteGateSchedule(ob[i])
                                    .then(data => {
                                        //console.log('recDelete data', data);-9
                                        if (!data) err = true;
                                        recDelete(ob, ++i, fn, err);
                                    });
                            } else fn(err);
                        }
                    }
                })
            },
            createSchedule() {
                this.selectedSchedule = {
                    id: 0,
                    gateId: this.gate.id,
                    name: 'New schedule',
                    enabled: true,
                };

                this.$bvModal.show('schedule-modal');
            },

            editSchedule(e) {
                this.selectedSchedule = e;
                this.$bvModal.show('schedule-modal');
            },
            
            getGateSchedules() {
                gateScheduleService.getGateScheduleList({
                    Id: this.gate.id,
                    Count: this.options.itemsPerPage,
                    SearchTerm: this.searchField,
                    Page: this.options.page
                })
                    .then((data) => {
                        this.gateScheduleList = data.items;
                        this.searchFieldIsLoading = false;
                        this.options.page = data.page;
                        this.pagination.totalListCount = data.totalListCount;
                        this.loading = false;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
        },
        computed: {
            headers() {
                return [
                    {
                        label: this.$t('common_name'),
                        sortable: true,
                        key: 'name'
                    },
                    {
                        label: 'Enabled',
                        sortable: true,
                        key: 'enabled',
                    },
                    {
                        label: this.$t('admin_dashboard_gate_schedules_creatededited'),
                        sortable: true,
                        key: 'modifiedOnUtc'
                    },
                    {
                        label: this.$t('admin_dashboard_gate_schedules_creatededitedby'),
                        sortable: true,
                        key: 'modifiedBy',
                    }
                ]
            },
        },
        
        watch: {
            options: {
                handler() {
                    this.getGateSchedules()
                },
                deep: true,
                immediate: true,
            },
            searchField: {
                handler() {
                    if (this.searchField.length == 0 || this.searchField.length > 1) {
                        const self = this;
                        clearTimeout(this.searchDebouncer);
                        this.searchDebouncer = setTimeout(() => {
                            self.getGateSchedules()
                        }, 400);
                    }
                }
            }
        },
        mounted() {
            this.$root.$on('dmx::saved::gateSchedule', () => {
                this.makeToast('Gate schedule', `Schedule has been saved`);
                this.$refs['schedule-modal'].hide();
                this.getGateSchedules();
            });

            this.$root.$on('dmx::created::gateSchedule', () => {
                this.makeToast('Gate schedule', `Schedule has been created`);
                this.$refs['schedule-modal'].hide();
                this.getGateSchedules();
            })
        },
        beforeDestroy() {
            this.$root.$off('dmx::saved::gateSchedule');
            this.$root.$off('dmx::created::gateSchedule');
        }
    }
</script>