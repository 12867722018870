<template>
    <div v-if="gate.id > 0 && stateUser">
        <b-row align-v="end">
            <b-col cols="6">
                <dmx-title backlink :bc-items="bcItems">{{ gate.systemName }}</dmx-title>
            </b-col>
            <b-col cols="6" class="d-flex align-items-end justify-content-end pb-4">
                <b-button v-if="stateUser.userRole >= userRoles.DemexAdmin" variant="danger" v-b-modal.delete-gate-modal
                          class="mr-1">
                    {{ $t('common_delete') }}
                </b-button>
                <b-button v-if="hasGatePermission(permissions.ManageInfo)" variant="success" @click="saveGate()">
                    {{ $t('common_save') }}
                </b-button>
            </b-col>
        </b-row>
        <!-- NAVIGATION TABS -->
        <br />
        <div>
            <b-tabs pills justified v-model="tabIndex" content-class="mt-4" no-nav-style nav-class="dmx-horizontal-tabs"
                    active-nav-item-class="dmx-horizontal-active-tab" nav-wrapper-class="dmx-horizontal-tabs-wrapper">
                <b-tab v-if="hasGatePermission(permissions.ViewStatus)" no-body>
                    <template #title>
                        <b-icon icon="lock-fill"></b-icon>
                        <br />
                        {{ $t('common_status') }}
                    </template>
                    <gate-status :gate="gate" :device-list="deviceList" :country-list="countryList"
                                 :time-zone-list="timeZoneList" :customer-owner-list="customerOwnerList"
                                 :gate-installer-list="gateInstallerList" @goto-tab-index="gotoTabIndex" />
                </b-tab>
                <b-tab v-if="hasGatePermission(permissions.ViewUsers)" no-body>
                    <template #title>
                        <b-icon icon="person-fill"></b-icon>
                        <br />
                        {{ $t('common_users') }}
                    </template>
                    <gate-users v-if="gate.id > 0" :gate="gate"></gate-users>
                    <!--<br /><br /><br />
                    <gate-user-list v-if="gate.id > 0" :gateId="gate.id"></gate-user-list>-->
                </b-tab>
                <b-tab v-if="hasGatePermission(permissions.ManageSchedules)" no-body>
                    <template #title>
                        <b-icon icon="calendar-week"></b-icon>
                        <br />
                        {{ $t('common_schedules') }}
                    </template>
                    <gate-schedules v-if="gate.id > 0" :gate="gate"></gate-schedules>
                </b-tab>
                <b-tab v-if="hasGatePermission(permissions.ViewLog)" no-body>
                    <template #title>
                        <b-icon icon="card-list"></b-icon>
                        <br />
                        {{ $t('common_logs') }}
                    </template>
                    <gate-log v-if="gate.id > 0" :gate="gate"></gate-log>
                </b-tab>
                <b-tab v-if="hasGatePermission(permissions.ManageSubscription)" no-body>
                    <template #title>
                        <b-icon icon="cash"></b-icon>
                        <br />
                        {{ $t('common_subscriptions') }}
                    </template>
                    <template>
                        <div class="text-center">
                            <div class="m-auto mb-3">
                                <h2>Go to subscriptions</h2>
                            </div>
                            <div>
                                <button @click="goToPayment()" class="btn btn-primary m-auto"
                                        type="button">
                                    Subscriptions
                                </button>
                            </div>
                        </div>
                    </template>
                    <!--<gate-subscriptions :gate="gate" :subscription-list="subcriptionList" />-->
                </b-tab>
                <b-tab v-if="hasGatePermission(permissions.ManageInfo)" no-body>
                    <template #title>
                        <b-icon icon="gear-fill"></b-icon>
                        <br />
                        {{ $t('common_settings') }}
                    </template>
                    <gate-settings :gate="gate" :gateIOList="gateIOList" :gateTypeList="gateTypeList" :icons="icons"
                                   :outputSignalTypes="outputSignalTypes" :inputSignalTypes="inputSignalTypes" />
                </b-tab>
            </b-tabs>
        </div>

        <b-modal size="md" id="delete-gate-modal" title="Delete gate" @ok="deleteGate()">
            <p>
                You are about to delete this gate (<b>{{ gate.name }}</b>).<br />
                <b>This operation cannot be undone!</b>
            </p>

            <template #modal-footer="{ hide, ok }">
                <b-row v-if="gate.deviceId > 0">
                    <b-col sm="12">
                        <b-form-checkbox v-model="gateDelete.deleteDevice" size="lg">Delete device</b-form-checkbox>
                    </b-col>
                </b-row>
                <b-button variant="primary" @click="hide()">
                    {{ $t('common_cancel') }}
                </b-button>
                <b-button variant="danger" @click="ok()">
                    {{ $t('common_delete') }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<!--table styles-->
<style>
    /* BOOTSTRAP unscoped */
    .dmx-gate-card {
        background-color: #f3f2e8;
        border-radius: 4px;
        padding: 16px;
    }

    .dmx-horizontal-tabs-wrapper {
        color: #dbd9c1 !important;
    }

    .dmx-horizontal-tabs {
        background: #003440 !important;
        border-radius: 6px !important;
        padding: 18px 0 0 0 !important;
    }

        .dmx-horizontal-tabs .nav-item a {
            color: #dbd9c1 !important;
        }

            .dmx-horizontal-tabs .nav-item a:hover {
                color: #fdfbe3 !important;
            }

    .dmx-horizontal-active-tab {
        background: none !important;
        border-bottom: 6px solid #dbd9c1;
        border-radius: 0 !important;
    }
</style>

<style scoped></style>

<script>
    import { mapState } from 'vuex';
    import gateService from '@/services/gateService';

    import router from '@/router';
    import { UserRoles, signalType, signalDirection } from '@/variables/variables';
    import { Permissions } from '@/variables/permissionVariables';

    import gateStatusComponent from '@/components/gates/gate-status.vue';
    import gateSettingsComponent from '@/components/gates/gate-settings.vue';
    import gateSubscriptionsComponent from '@/components/gates/gate-subscriptions.vue';
    import gateLogComponent from '@/components/gates/gate-log.vue';
    import gateUsersComponent from '@/components/gates/gateUsers/gate-users.vue';
    import gateSchedulesComponent from '@/components/gates/gateSchedules/gate-schedules.vue';

    //import gateScheduleListComponent from '../gateSchedule/GateScheduleList'; // old
    //import gateUserListComponent from '../gateUsers/GateUserList'; // old

    export default {
        props: ["gateId"],
        components: {
            'gate-status': gateStatusComponent,
            'gate-settings': gateSettingsComponent,
            'gate-subscriptions': gateSubscriptionsComponent,
            'gate-log': gateLogComponent,
            'gate-schedules': gateSchedulesComponent,
            //'gate-schedule-list': gateScheduleListComponent, // old
            'gate-users': gateUsersComponent,
            //'gate-user-list': gateUserListComponent, // old
        },
        data: () => ({
            gate: {
                id: 0,
                systemName: '',
                name: '',
                orderNumber: '',
                gateDescription: '',
                roleId: 0,
                deviceId: 0,
                ownerCustomerId: 0,
                gateInstallerCustomerId: 0,
                countryId: 0,
                timeZoneId: 0,
                createdOnUtc: null,
                deletedOnUtc: null,
                deleted: false,
                status: 0,
                gateSchedule: { enabled: false },
                device: '',
                demoMode: false,
                discount: 0
            },
            currentItem: 'tab-gate',
            deviceList: [],

            gateOpened: false,
            boolArray: [],
            deleteDialog: false,


            gateIOList: [],
            gateTypeList: [],
            icons: [],
            subcriptionList: [],
            countryList: [],
            customerOwnerList: [],
            gateInstallerList: [],
            timeZoneList: [],
            outputSignalTypes: [],
            inputSignalTypes: [],
            gateDelete: {
                gateId: 0,
                deleteDevice: true,
            },
            signalrUiPayload: null,
            tabIndex: 0,
            userRoles: UserRoles,
            permissions: Permissions,
        }),
        computed: {
            ...mapState('authentication', {
                stateUser: 'user',
            }),
            bcItems() {
                return [
                    {
                        text: 'gates',
                        href: '/gates',
                    },
                    {
                        text: this.gate.systemName,
                        href: '/gate/editGate/' + this.gate.id,
                        active: true,
                    }
                ]
            },
        },
        methods: {

            deleteGate() {
                this.gateDelete.gateId = this.gate.id;
                gateService.deleteGate(this.gateDelete)
                    .then(data => {
                        this.makeToast(`Gate`, `Gate ${this.gate.name} has been deleted.`);
                        router.go(-1)
                    });
            },
            goToPayment() {
                router.push({ name: 'Payment', params: { gateId: this.gate.id } })
            },
            saveGate() {
                const self = this;
                if (this.gateId > 0) {
                    gateService.updateGate(this.gate)
                        .then(data => {
                            this.makeToast(`Gate`, `Gate values has been saved.`);
                            //router.go(-1)
                        });
                } else {
                    gateService.createGate(this.gate)
                        .then(data => {
                            this.makeToast(`Gate`, `Gate has been created.`);
                            router.go(-1);
                        }).catch((err) => {
                            this.makeToast(`Gate`, `Unable to create gate. Err: ${err}`, 'danger');
                        });
                }
            },
            fillView(data) {
                if (data.gate) {
                    this.gate = data.gate;
                }
                this.deviceList = data.devices;
                this.gateIOList = data.gateIOs;
                this.gateTypeList = data.gateTypeList;
                this.icons = data.icons;
                this.subcriptionList = data.subscriptions;
                this.countryList = data.countries;
                this.timeZoneList = data.timeZones;

                this.customerOwnerList = data.customerOwners;
                this.gateInstallerList = data.customerGateInstallers;

                if (this.gate.id) {
                    this.outputSignalTypes = data.outputSignalTypes;
                    this.inputSignalTypes = data.inputSignalTypes;
                }
            },
            gotoTabIndex(ind) {
                this.tabIndex = ind;
            },
            gateStatusChange(data) {
                if (data.gateId == this.gate.id) this.gate.gateStatus = data;
            },
            gateScheduleChange(data) {
                if (data.gateId == this.gate.id) {
                    this.gate.gateSchedule = data;
                    //console.log('-------------------------> Gate schedule status:');
                    //console.log(this.gate.gateSchedule);
                    //console.log('------------------------------------------------');
                }
            }
        },
        created() {

        },
        mounted() {
            let self = this;

            const desiredTabIndex = parseInt(this.$route.query.tabIndex) || 0;
            this.tabIndex = desiredTabIndex;

            this.$socket.on('GateStatusChange', (data) => {
                //console.log('-----------------------------> got GateStatusChange');
                //console.log(data);
                //console.log('---------------------------------------------------');
                self.gateStatusChange(data);
            });

            this.$socket.on('GateScheduleChange', (data) => {
                //console.log('-----------------------------> got GateScheduleChange');
                //console.log(data);
                //console.log('---------------------------------------------------');
                self.gateScheduleChange(data);
            });

            if (this.gateId > 0) {
                gateService.getGate(this.gateId).then(data => {
                    this.gateData = data;
                    this.fillView(data);
                    this.boolArray = data.boolArray;
                    if (this.boolArray) {
                        this.gateOpened = this.boolArray[10].status;
                    }
                });
            } else {
                gateService.newGate().then(data => {
                    this.fillView(data);
                });
            }
        },
        beforeDestroy() {
            this.$socket.off('GateStatusChange', (err) => { });
            this.$socket.off('GateScheduleChange', (err) => { });
        }

    }
</script>