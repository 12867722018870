<template>
    <div>
        <b-row class="mb-2">
            <b-col>
                From date: <b-form-datepicker id="from-date" v-model="options.fromDate"></b-form-datepicker>
            </b-col>
            <b-col>
                To date: <b-form-datepicker id="to-date" v-model="options.toDate"></b-form-datepicker>
            </b-col>
            <b-col>
                Search: <b-input v-model="searchField" placeholder="Search log entries"></b-input>
            </b-col>
        </b-row>
        <b-table id="gates-log-table"
                 thead-class="dmx-thead-block"
                 outlined
                 striped
                 bordered
                 :busy="loading"
                 :items="gateLogList"
                 :fields="headers">
        </b-table>
        <b-pagination align="center" v-model="options.page"
                      :total-rows="pagination.totalListCount"
                      :per-page="options.itemsPerPage"
                      aria-controls="gates-log-table">
        </b-pagination>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import gateLogService from '@/services/gateLogService';
    import moment from 'moment';
    import i18n from '@/i18n';

    export default {
        props: {
            gate: {},
        },
        data() {
            return {
                loading: true,
                searchField: '',
                searchFieldIsLoading: false,
                searchDebouncer: 0,
                typeList: [],
                gateLogList: [],
                options: {
                    type: 0,
                    fromDate: null,
                    toDate: null,
                    page: 1,
                    itemsPerPage: 10,
                },
                pagination: {
                    totalListCount: 0
                },
            }
        },
        methods: {
            getTypes() {
                gateLogService.getTypes().then((data) => {
                    this.typeList = data;
                    this.loading = false;
                }).catch(function (error) {
                    alert(error);
                });
            },
            editGateLog(e) {
                // router.push({ name: 'gateLogEdit', params: { gateLogId: parseInt(e.id) } })
            },

            getGateLogs() {
                let reqOb = {
                    Id: this.gate.id,
                    Count: this.options.itemsPerPage,
                    Gates: [],
                    SortBy: 'createdOn',
                    SearchTerm: this.searchField,
                    SortDesc: true,
                    Page: this.options.page,
                    Type: this.options.type,
                    FromDate: this.options.fromDate,
                    ToDate: this.options.toDate
                };

                gateLogService.getGateLogList(reqOb)
                    .then((data) => {
                        //console.log('getGateLogs data', data);
                        if (data.items) {
                            data.items.map(x => {
                                //x.createdOnUtc = moment(x.createdOnUtc).format('YYYY-MM-DD HH:mm:ss');
                                x.createdOn = moment(x.createdOn).format('YYYY-MM-DD HH:mm:ss');
                                x.title = i18n.t(x.title);
                                x.text = i18n.t(x.text);
                                x.message = i18n.t(x.message);
                            });
                            this.gateLogList = data.items;
                            this.searchFieldIsLoading = false;
                            this.options.page = data.page;
                            this.pagination.totalListCount = data.totalListCount;
                        }
                        this.loading = false;
                    })
                    .catch(function (error) {
                        //console.log(`${error}`);
                        alert(error);
                    });
            },

        },
        computed: {
            headers() {
                return [
                    {
                        label: 'Created',
                        sortable: true,
                        key: 'createdOn'
                    },
                    {
                        label: 'Type',
                        sortable: true,
                        key: 'type'
                    },
                    {
                        label: 'Icon',
                        sortable: true,
                        key: 'icon'
                    },
                    {
                        label: 'Title',
                        sortable: true,
                        key: 'title'
                    },
                    {
                        label: 'Text icon',
                        sortable: true,
                        key: 'textIcon',
                    },
                    {
                        label: 'Text',
                        sortable: true,
                        key: 'text'
                    },
                    {
                        label: 'Message',
                        sortable: true,
                        key: 'message'
                    }
                ]
            },
        },

        watch: {
            options: {
                handler() {
                    this.getGateLogs()
                },
                deep: true,
                immediate: true,
            },
            searchField: {
                handler() {
                    if (this.searchField.length == 0 || this.searchField.length > 1) {
                        const self = this;
                        clearTimeout(this.searchDebouncer);
                        this.searchDebouncer = setTimeout(() => {
                            self.getGateLogs()
                        }, 400);
                    }
                }
            }
        }
    }
</script>