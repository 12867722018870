<template>
    <div>
        <b-row>
            <b-col lg="6" sm="12">
                <div class="dmx-gate-card">
                    <b-row no-gutters align-v="center">
                        <b-col sm="3" align="right" class="pr-2">
                            ID:
                        </b-col>
                        <b-col sm="9">
                            <b-form-input v-model="gate.id" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Created UTC:
                        </b-col>
                        <b-col sm="9">
                            <b-form-input :value="utcFormatted(gate.createdOnUtc)" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Last service:
                        </b-col>
                        <b-col sm="9">
                            <b-form-datepicker v-model="gate.lastServiceDate"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Battery changed:
                        </b-col>
                        <b-col sm="9">
                            <b-form-datepicker v-model="gate.lastBatteryChangeDate"></b-form-datepicker>
                        </b-col>
                    </b-row>
                    <b-row no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Discount:
                        </b-col>
                        <b-col sm="9">
                            <b-form-input v-model="gate.discount"></b-form-input>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col lg="6" sm="12">
                <div class="dmx-gate-card">
                    <b-row no-gutters align-v="center" class="mt-4">
                        <b-col sm="3" align="right" class="pr-2">
                            Gate ownage:
                        </b-col>
                        <b-col sm="9">
                            <b-button size="sm" v-b-modal.transfer-ownage-modal variant="danger">Transfer gate ownage</b-button>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-row no-gutters align-v="center" class="mt-2">
                        <b-col sm="3" align="right" class="pr-2">
                            Demo mode:
                        </b-col>
                        <b-col sm="9">
                            <b-form-checkbox v-model="gate.demoMode" />
                        </b-col>
                    </b-row>
                </div>
            </b-col>
        </b-row>

        <!--Model chooser-->
        <b-row>
            <b-col sm="6">
                <div class="dmx-gate-card">
                    <b-row class="mt-2" no-gutters>
                        <b-col sm="3" align="right" class="pr-2">
                            Model:
                        </b-col>
                        <b-col sm="9">
                            <b-select :options="gateIOListFormatted" v-model="selectedGateIO" @change="onChange"></b-select>
                        </b-col>
                    </b-row>
                    <b-row class="mt-2" no-gutters>
                        <b-col sm="3" align="right" class="pr-2">
                            Gate type:
                        </b-col>
                        <b-col sm="9">
                            <b-select :options="gateTypeList" v-model="gate.gateIO.type"></b-select>
                        </b-col>
                    </b-row>
                </div>
            </b-col>
            <b-col sm="6">
            </b-col>
        </b-row>

        <edit-signals :gateIO="gate.gateIO" :availableOutputSignals="outputSignalTypes" :availableInputSignals="inputSignalTypes" :icons="icons"></edit-signals>

        <b-modal size="md" id="transfer-ownage-modal" title="Transfer gate ownage" @ok="gateTransfer">
            <p>
                You are about to transfer ownership to this gate (<b>{{ gate.name }}</b>). Please state below the email address of the recieving part.<br />
                <b>This operation cannot be undone!</b>
            </p>

            <b-input v-model="gateTransferAddress" placeholder="Email address"></b-input>
            <template #modal-footer="{ hide, ok }">
                <b-button variant="primary" @click="hide()">
                    ABORT
                </b-button>
                <b-button variant="danger" @click="ok()">
                    TRANSFER
                </b-button>
            </template>
        </b-modal>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import gateService from '@/services/gateService';

    import editSignalsComponent from '@/components/signals/edit-signals.vue';

    export default {
        props: {
            gate: {},
            gateIOList: [],
            gateTypeList: [],
            icons: [],
            outputSignalTypes: [],
            inputSignalTypes: []
        },
        components: {
            'edit-signals': editSignalsComponent
        },
        data() {
            return {
                gateIOListFormatted: [],
                gateTransferAddress: '',
                selectedGateIO: 0
            }
        },
        methods: {
            onChange: function () {
                this.gate.gateIO.inputSignals = this.gateIOList[this.selectedGateIO].inputSignals;
                this.gate.gateIO.outputSignals = this.gateIOList[this.selectedGateIO].outputSignals;
                this.gate.gateIO.type = this.gateIOList[this.selectedGateIO].type;
            },
            gateTransfer() {
                gateService.handOverGateOwnership({ GateId: this.gate.id, RecipientEmailAddress: this.gateTransferAddress })
                    .then(data => {
                        switch (data) {
                            case "gate":
                                this.makeToast('Transfer gate ownage', `Unknown error, couldn't find gate.`, 'danger');
                                break;
                            case "user":
                                this.makeToast('Transfer gate ownage', `Couldn't find recieving email address! Please try again.`, 'danger');
                                break;
                            default:
                                this.makeToast('Transfer gate ownage', `Ownage of gate (${this.gate.name}) has successfully been transfered to ${this.gateTransferAddress}`);
                                $router.go(-1);
                        }
                    });
            }
        },

        watch: {
            gateIOList: {
                handler() {
                    this.gateIOListFormatted = [{ value: 0, text: 'Choose gate model' }];
                    for (let i in this.gateIOList) {
                        this.gateIOListFormatted.push({ value: i, text: this.gateIOList[i].name });
                    }
                },
                immediate: true,
            }
        },
        beforeMount() {
            if (this.gate.gateIO == null) {
                this.gate.gateIO = {
                    name: String,
                    filtertime: Number,
                    outputSignals: new Array,
                    inputSignals: new Array,
                    type: new Array
                };
            }
        }
    }
</script>