<template>
    <div>
        <b-row class="mb-2" align-v="center">
            <b-col>
                {{ pagination.totalListCount }} users found
            </b-col>
            <b-col>
                <b-input v-model="searchField" placeholder="Search gate users"></b-input>
            </b-col>
            <b-col class="d-flex align-items-end justify-content-end">
                <b-button variant="primary" @click="" class="mr-1">
                    <b-icon icon="plus" class="mr-2"></b-icon> Add users from list
                </b-button>
            </b-col>
        </b-row>
        <div class="d-flex align-items-center mb-1">
            <b-link v-if="gateUsersSelected.length" @click="bulkRemoveUserGateMapping()" class="mr-3" style="font-size: .9em;">
                <span style="color: #c00 !important;"><b-icon icon="x"></b-icon> Disconnect selected users</span>
            </b-link>
        </div>
        <div v-if="gateUsersList.length">
            <b-table id="gate-users-table"
                     thead-class="dmx-thead-block"
                     outlined
                     striped
                     bordered
                     hover
                     selectable
                     @row-selected="onRowSelected"
                     :busy="loading"
                     :items="gateUsersList"
                     :fields="headers">

                <template #cell(name)="{ item }">
                    <div class="d-flex justify-content-start align-items-center">
                        <b-form-checkbox v-model="gateUsersSelected" :value="item.userId"></b-form-checkbox>
                        {{ item.firstName }} {{ item.lastName }}
                    </div>
                </template>

                <template #cell(role)="{ item, index }">
                    <span class="small"><b>{{ getUserGateRoleFormatted(item.userGateRole) }}</b></span>
                    <b-link v-if="hasGatePermission(permissions.ManageUserPermissions)" class="ml-3 small" @click="editGateUser(index)">
                        Edit permissions
                    </b-link>
                </template>
                <template #cell(notifications)="{ item, index }">
                    <b-link  class="ml-3 small" @click="editGateUserNotifications(index)">
                        Edit notification settings
                    </b-link>
                </template>

            </b-table>
            <b-pagination align="center" v-model="options.page"
                          :total-rows="pagination.totalListCount"
                          :per-page="options.itemsPerPage"
                          aria-controls="gate-users-table">
            </b-pagination>
        </div>
        <div v-else align="center">No connected users found, click to add</div>
        <b-modal size="lg" id="permission-modal" ref="permission-modal" hide-footer>
            <template #modal-title>
                <h5>
                    Edit gate permissions for user <b>
                        {{ gateUsersList[gateUserInEdit].firstName }}
                        {{ gateUsersList[gateUserInEdit].lastName }}
                    </b> on <b>{{ gate.name }}</b>
                </h5>
            </template>
            <gate-user-permissions v-if="gateUserInEdit !== false" :gateUser="gateUsersList[gateUserInEdit]" :gate="gate"/>
        </b-modal>
        <b-modal size="lg" id="notifications-modal" ref="notifications-modal" hide-footer>
            <template #modal-title>
                <h5>
                    Edit gate notification settings for user <b>
                    {{ gateUsersList[gateUserInEdit].firstName }}
                    {{ gateUsersList[gateUserInEdit].lastName }}
                </b> on <b>{{ gate.name }}</b>
                </h5>
            </template>
            <gate-user-notifications v-if="gateUserInEdit !== false" :gateUser="gateUsersList[gateUserInEdit]" :gate="gate"/>
        </b-modal>
    </div>
</template>

<style scoped>
    
</style>

<script>
    import { mapState } from 'vuex';
    import { UserRoles } from '@/variables/variables';
    import { Permissions } from '@/variables/permissionVariables';
    import gateService from '@/services/gateService';
    import router from '@/router';
    import accountService from '@/services/accountService';
    import gateUserNotifications from "./gate-user-notifications.vue"
    import gateUserPermissions from './gate-user-permissions.vue';

    export default {
        components: {
            'gate-user-permissions': gateUserPermissions,
            'gate-user-notifications': gateUserNotifications,
        },
        props: {
            gate: {},
        },
        data() {
            return {
                loading: true,
                searchField: '',
                searchFieldIsLoading: false,
                searchDebouncer: 0,
                gateUsersSelected: [],
                gateUsersList: [],
                gateUserInEdit: false,
                options: {
                    page: 1,
                    itemsPerPage: 10,
                },
                pagination: {
                    totalListCount: 0
                },
                availableGateRoles: [],
                userRoles: UserRoles,
                permissions: Permissions,
            }
        },
        methods: {
            getUserGateRoleFormatted(userRole) {
                for (let i in this.availableGateRoles) {
                    if (this.availableGateRoles[i].value == userRole) return this.availableGateRoles[i].text;
                }
            },
            editGateUser(i) {
                this.gateUserInEdit = i;
                this.$refs['permission-modal'].show();
            },
            editGateUserNotifications(i) {
                this.gateUserInEdit = i;
                this.$refs[`notifications-modal`].show()
            },
            onRowSelected(e) {
                router.push({ name: 'editUser', params: { userId: parseInt(e[0].userId) } })
            },
            getGateUsers() {
                this.loading = true;
                let reqOb = {
                    Id: this.gate.id,
                    Count: this.options.itemsPerPage,
                    Users: [],
                    SearchTerm: this.searchField,
                    Page: this.options.page,
                };
           
                gateService.getGateUsers(reqOb)
                    .then((res) => {
                        //console.log('getGateUsers data', res);
                        if (res.gateUsers) {
                            let data = res.gateUsers;
                            this.gateUsersList = data.items;
                            this.options.page = data.page;
                            this.pagination.totalListCount = data.totalListCount | 0;
                            this.$emit('update-users-total', this.pagination.totalListCount);
                        }
                        this.searchFieldIsLoading = false;
                        this.loading = false;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            bulkRemoveUserGateMapping() {
                this.$bvModal.msgBoxConfirm('Please confirm to disconnect selected users.', {
                    title: 'Disconnection',
                    okVariant: 'danger',
                    okTitle: 'DISCONNECT',
                    cancelTitle: 'CANCEL',
                    hideHeaderClose: false,
                    centered: true
                }).then(goahead => {
                    if (goahead) {
                        accountService.bulkRemoveUserGateMapping({ gateId: this.gate.id, userIds: this.gateUsersSelected })
                            .then((data) => {
                                this.getGateUsers();
                                this.makeToast('Gate users', `Selected ${this.gateUsersSelected.length == 1 ? 'user' : 'users'} has been disconnected`);
                                this.gateUsersSelected = [];
                            });
                    }
                })
            },
            getUserGateRoleFormatted(userRole) {
                for (let i in this.availableGateRoles) {
                    if (this.availableGateRoles[i].value == userRole) return this.availableGateRoles[i].text;
                }
            },
            getAvailableGateRoles() {
                this.avaiableGateRoles = [];
                gateService.getAvailableGateRoles()
                    .then((data) => {
                        this.availableGateRoles = [];
                        for (let key in data) {
                            this.availableGateRoles.push({ value: data[key], text: this.$t('gateRole_' + key) });
                        }
                    });
            },
            hasGatePermission(perm) {
                if (this.stateUser.userRole >= this.userRoles.DemexAdmin) return true;
                else {
                    for (let i in this.stateUser.gates) {
                        if (this.stateUser.gates[i].id == this.gate.id) {
                            return this.stateUser.gates[i].userGatePermissions.includes(perm);
                        }
                    }
                    return false;
                }
            },
        },
        computed: {
            ...mapState({
                stateUser: store => store.authentication.user,
            }),
            headers() {
                return [
                    {
                        label: 'Name',
                        key: 'name',
                    },
                    {
                        label: 'Company',
                        sortable: true,
                        key: 'company',
                    },
                    {
                        label: 'Email address',
                        sortable: false,
                        key: 'email',
                    },
                    {
                        label: 'Phone',
                        sortable: false,
                        key: 'phone',
                    },
                    {
                        label: 'Role/Permissions',
                        key: 'role'
                    },
                    {
                        label: 'Notifications',
                        key: 'notifications'
                    },
                ]
            },
        },
        
        watch: {
            options: {
                handler() {
                    this.getGateUsers()
                },
                deep: true,
                immediate: true,
            },
            searchField: {
                handler() {
                    if (this.searchField.length == 0 || this.searchField.length > 1) {
                        const self = this;
                        clearTimeout(this.searchDebouncer);
                        this.searchDebouncer = setTimeout(() => {
                            self.getGateUsers()
                        }, 400);
                    }
                }
            }
        },
        mounted() {
            this.$root.$on('dmx::saved::gateUserPermissions', () => {
                this.$refs['permission-modal'].hide();
                this.makeToast('Gate user permissions', `User permissions has been saved`);
                this.getGateUsers();
            });
            this.$root.$on('dmx::saved::gateUserNotifications', () => {
                this.$refs['notifications-modal'].hide();
                this.makeToast('Gate user notifications', `User notification settings has been saved`);
                this.getGateUsers();
            });
            
            this.getAvailableGateRoles();
        },
        beforeDestroy() {
            this.$root.$off('dmx::saved::gateUserPermissions');
        }
    }
</script>